import { createStore } from 'vuex';
import { getFirestore, collection, query, getDocs, doc, limit, orderBy, startAfter, where, getDoc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { db } from "@/firebase";

export default createStore({
    state: {
        isLoggedIn: false,
        userUID: null,
        userPosts: [],
        malePosts: [],
        femalePosts: [],
        searchConditions: {
            twitterId: '',
            content: '',
            place: '',
            minPrice: '',
            maxPrice: '',
        },
        isSearchActive: false,
        maleSearchResults: [],
        femaleSearchResults: [],
        lastVisibleUserPost: null,
        isAllUserPostsLoaded: false,
        lastVisibleMale: null,
        lastVisibleFemale: null,
        lastVisibleMaleSearchResult: null,
        lastVisibleFemaleSearchResult: null,
        isAllMaleSearchResultsLoaded: false,
        isAllFemaleSearchResultsLoaded: false,
        infiniteId: 0,
        isSearching: false,
        errorMessage: '',
    },
    mutations: {
        setLoggedIn(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        setUserUID(state, userUID) {
            state.userUID = userUID;
        },
        addPost(state, post) {
            state.userPosts.push(post);
        },
        removePost(state, postId) {
            state.userPosts = state.userPosts.filter(post => post.id !== postId);
        },
        appendPosts(state, newPosts) {
            state.userPosts = [...state.userPosts, ...newPosts];
        },
        setAllUserPostsLoaded(state, value) {
            state.isAllUserPostsLoaded = value;
        },
        updateLastVisibleUserPost(state, lastVisible) {
            state.lastVisibleUserPost = lastVisible;
        },
        updateLastVisibleMale(state, lastVisible) {
            state.lastVisibleMale = lastVisible;
        },
        updateLastVisibleFemale(state, lastVisible) {
            state.lastVisibleFemale = lastVisible;
        },
        setMalePosts(state, posts) {
            state.malePosts = posts;
        },
        setFemalePosts(state, posts) {
            state.femalePosts = posts;
        },
        appendMalePosts(state, newPosts) {
            const uniqueNewPosts = newPosts.filter(newPost =>
                !state.malePosts.some(existingPost => existingPost.id === newPost.id));
            state.malePosts = [...state.malePosts, ...uniqueNewPosts];
            state.isAllMalePostsLoaded = newPosts.length < 5;
        },
        appendFemalePosts(state, newPosts) {
            const uniqueNewPosts = newPosts.filter(newPost =>
                !state.femalePosts.some(existingPost => existingPost.id === newPost.id));
            state.femalePosts = [...state.femalePosts, ...uniqueNewPosts];
            state.isAllFemalePostsLoaded = newPosts.length < 5;
        },
        setAllMalePostsLoaded(state, value) {
            state.isAllMalePostsLoaded = value;
        },
        setAllFemalePostsLoaded(state, value) {
            state.isAllFemalePostsLoaded = value;
        },
        resetLastVisibleMale(state) {
            state.lastVisibleMale = null;
        },
        resetLastVisibleFemale(state) {
            state.lastVisibleFemale = null;
        },
        clearSearchResults(state) {
            state.isSearchActive = false;
            state.lastVisibleMaleSearchResult = null;
            state.lastVisibleFemaleSearchResult = null;
            state.isAllMalePostsLoaded = false;
            state.isAllFemalePostsLoaded = false;
            state.isAllMaleSearchResultsLoaded = false;
            state.isAllFemaleSearchResultsLoaded = false;
        },
        setSearchConditions(state, conditions) {
            state.searchConditions = conditions;
        },
        clearSearchConditions(state) {
            state.searchConditions = {
                twitterId: '',
                content: '',
                place: '',
                minPrice: null,
                maxPrice: null,
            };
        },
        setMaleSearchResults(state, maleResults) {
            state.maleSearchResults = maleResults;
        },
        setFemaleSearchResults(state, femaleResults) {
            state.femaleSearchResults = femaleResults;
        },
        setIsSearching(state, isSearching) {
            state.isSearching = isSearching;
        },
        setIsSearchActive(state, active) {
            state.isSearchActive = active;
        },
        setLastVisibleMaleSearchResult(state, lastVisible) {
            state.lastVisibleMaleSearchResult = lastVisible;
        },
        setLastVisibleFemaleSearchResult(state, lastVisible) {
            state.lastVisibleFemaleSearchResult = lastVisible;
        },
        setAllMaleSearchResultsLoaded(state, value) {
            state.isAllMaleSearchResultsLoaded = value;
        },
        setAllFemaleSearchResultsLoaded(state, value) {
            state.isAllFemaleSearchResultsLoaded = value;
        },
        appendMaleSearchResults(state, maleResults) {
            state.maleSearchResults = [...state.maleSearchResults, ...maleResults];
        },
        appendFemaleSearchResults(state, femaleResults) {
            state.femaleSearchResults = [...state.femaleSearchResults, ...femaleResults];
        },
        setErrorMessage(state, message) {
            state.errorMessage = message;
        },
        clearErrorMessage(state) {
            state.errorMessage = '';
        },
        resetInfiniteScroll(state) {
            state.infiniteId += 1;
        },
        setReplies(state, { postId, replies }) {
            let found = false;
            const malePostIndex = state.malePosts.findIndex(post => post.id === postId);
            if (malePostIndex !== -1) {
                state.malePosts[malePostIndex].replies = replies;
                found = true;
            }
            const femalePostIndex = state.femalePosts.findIndex(post => post.id === postId);
            if (femalePostIndex !== -1) {
                state.femalePosts[femalePostIndex].replies = replies;
                found = true;
            }
            const maleSearchResultIndex = state.maleSearchResults.findIndex(post => post.id === postId);
            if (maleSearchResultIndex !== -1) {
                state.maleSearchResults[maleSearchResultIndex].replies = replies;
                found = true;
            }
            const femaleSearchResultIndex = state.femaleSearchResults.findIndex(post => post.id === postId);
            if (femaleSearchResultIndex !== -1) {
                state.femaleSearchResults[femaleSearchResultIndex].replies = replies;
                found = true;
            }
            if (!found) {
                return;
            }
        },
    },
    actions: {
        async fetchPostsByGender({ commit }, gender) {
            const genderQuery = gender === '男性' ? '男性' : '女性';
            let postsQuery = query(collection(db, 'review'), where('gender', '==', genderQuery), orderBy('postedAt', 'desc'), limit(5));
            try {
                const querySnapshot = await getDocs(postsQuery);
                const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                if (gender === '男性') {
                    commit('setMalePosts', posts);
                } else {
                    commit('setFemalePosts', posts);
                }
            } catch (error) {
                return;
            }
        },
        clearGenderPosts({ commit }, gender) {
            if (gender === '男性') {
                commit('setMalePosts', []);
                commit('resetLastVisibleMale');
                commit('resetInfiniteScroll');
            } else {
                commit('setFemalePosts', []);
                commit('resetLastVisibleFemale');
                commit('resetInfiniteScroll');
            }
        },
        async addNewPost({ commit, dispatch }, postData) {
            const { addDoc } = await import('firebase/firestore');

            try {
                const docRef = await addDoc(collection(db, 'review'), postData);
                const newPost = { id: docRef.id, ...postData };
                commit('addPost', newPost);
                dispatch('clearGenderPosts', postData.gender);
            } catch (error) {
                dispatch("showErrorMessage", "新しい投稿の追加に失敗しました。");
            }
        },
        async deletePost({ commit, dispatch }, postId) {
            const firebaseModules = await import('@/firebase');
            const storage = firebaseModules.storage;
            const { deleteDoc } = await import('firebase/firestore');
            try {
                const postRef = doc(db, 'review', postId);
                const postSnap = await getDoc(postRef);
                if (!postSnap.exists()) {
                    return;
                }
                const postData = postSnap.data();
                await deleteDoc(postRef);
                commit('removePost', postId);
                if (postData.images && postData.images.length) {
                    const deletePromises = postData.images.map(imagePath => {
                        const imageRef = ref(storage, imagePath);
                        return deleteObject(imageRef);
                    });
                    await Promise.all(deletePromises);
                }
                dispatch('clearGenderPosts', postData.gender);
            } catch (error) {
                dispatch("showErrorMessage", "投稿の削除に失敗しました。");
            }
        },
        async loadMoreMalePosts({ commit, state }) {
            if (state.isAllMalePostsLoaded) return;
            let postsQuery = query(
                collection(db, 'review'),
                where('gender', '==', '男性'),
                orderBy('postedAt', 'desc'),
                startAfter(state.lastVisibleMale || {}),
                limit(5)
            );
            const querySnapshot = await getDocs(postsQuery);
            if (!querySnapshot.empty) {
                const newPosts = [];
                querySnapshot.forEach(doc => {
                    newPosts.push({ id: doc.id, ...doc.data() });
                });
                commit('appendMalePosts', newPosts);
                commit('updateLastVisibleMale', querySnapshot.docs[querySnapshot.docs.length - 1]);
            } else {
                commit('setAllMalePostsLoaded', true);
            }
        },
        async loadMoreFemalePosts({ commit, state }) {
            if (state.isAllFemalePostsLoaded) return;
            let postsQuery = query(
                collection(db, 'review'),
                where('gender', '==', '女性'),
                orderBy('postedAt', 'desc'),
                startAfter(state.lastVisibleFemale || {}),
                limit(5)
            );
            const querySnapshot = await getDocs(postsQuery);
            if (!querySnapshot.empty) {
                const newPosts = [];
                querySnapshot.forEach(doc => {
                    newPosts.push({ id: doc.id, ...doc.data() });
                });
                commit('appendFemalePosts', newPosts);
                commit('updateLastVisibleFemale', querySnapshot.docs[querySnapshot.docs.length - 1]);
                if (querySnapshot.docs.length < 5) {
                    commit('setAllFemalePostsLoaded', true);
                }
            } else {
                commit('setAllFemalePostsLoaded', true);
            }
        },
        async loadMoreUserPosts({ commit, state }, userUID) {
            if (state.isAllUserPostsLoaded) return;
            let postsQuery = query(
                collection(db, 'review'),
                where('userId', '==', userUID),
                orderBy('postedAt', 'desc'),
                limit(5)
            );
            if (state.lastVisibleUserPost) {
                postsQuery = query(
                    collection(db, 'review'),
                    where('userId', '==', userUID),
                    orderBy('postedAt', 'desc'),
                    startAfter(state.lastVisibleUserPost),
                    limit(5)
                );
            }
            const documentSnapshots = await getDocs(postsQuery);
            if (documentSnapshots.empty || documentSnapshots.docs.length < 5) {
                commit('setAllUserPostsLoaded', true);
                return;
            }
            const newPosts = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            commit('appendPosts', newPosts);
            commit('updateLastVisibleUserPost', documentSnapshots.docs[documentSnapshots.docs.length - 1]);
        },
        async searchReviews({ commit }, { twitterId, content, place, minPrice, maxPrice }) {
            commit('setIsSearching', true);
            commit('setIsSearchActive', true);
            const db = getFirestore();
            const buildQuery = (gender) => {
                let q = query(collection(db, 'review'), where('gender', '==', gender));

                if (twitterId.trim()) {
                    q = query(q, where('twitter_id', '==', twitterId.trim()));
                }
                if (content.trim()) {
                    q = query(q, where('content', '==', content.trim()));
                }
                if (place.trim()) {
                    q = query(q, where('place', '==', place.trim()));
                }
                if (minPrice) {
                    q = query(q, where('price', '>=', minPrice));
                }
                if (maxPrice) {
                    q = query(q, where('price', '<=', maxPrice));
                }
                if (minPrice || maxPrice) {
                    q = query(q, orderBy('price'), orderBy('postedAt', 'desc'), limit(5));
                } else {
                    q = query(q, orderBy('postedAt', 'desc'), limit(5));
                }
                return q;
            };
            let maleQuery = buildQuery('男性');
            const maleQuerySnapshot = await getDocs(maleQuery);
            const maleResults = maleQuerySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            commit('setMaleSearchResults', maleResults);
            commit('setLastVisibleMaleSearchResult', maleQuerySnapshot.docs[maleQuerySnapshot.docs.length - 1] || null);
            let femaleQuery = buildQuery('女性');
            const femaleQuerySnapshot = await getDocs(femaleQuery);
            const femaleResults = femaleQuerySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            commit('setFemaleSearchResults', femaleResults);
            commit('setLastVisibleFemaleSearchResult', femaleQuerySnapshot.docs[femaleQuerySnapshot.docs.length - 1] || null);
            commit('setIsSearching', false);
        },
        async loadMoreMaleSearchResults({ commit, state }) {
            const { searchConditions } = state;
            const db = getFirestore();
            let q = query(collection(db, 'review'), where('gender', '==', '男性'));
            if (searchConditions.twitterId.trim()) {
                q = query(q, where('twitter_id', '==', searchConditions.twitterId.trim()));
            }
            if (searchConditions.content.trim()) {
                q = query(q, where('content', '==', searchConditions.content.trim()));
            }
            if (searchConditions.place.trim()) {
                q = query(q, where('place', '==', searchConditions.place.trim()));
            }
            if (searchConditions.minPrice) {
                q = query(q, where('price', '>=', searchConditions.minPrice));
            }
            if (searchConditions.maxPrice) {
                q = query(q, where('price', '<=', searchConditions.maxPrice));
            }
            if (searchConditions.minPrice || searchConditions.maxPrice) {
                q = query(q, orderBy('price'), orderBy('postedAt', 'desc'));
            } else {
                q = query(q, orderBy('postedAt', 'desc'));
            }
            if (state.lastVisibleMaleSearchResult) {
                q = query(q, startAfter(state.lastVisibleMaleSearchResult), limit(5));
            } else {
                q = query(q, limit(5));
            }
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const newResults = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    commit('appendMaleSearchResults', newResults);
                    commit('setLastVisibleMaleSearchResult', querySnapshot.docs[querySnapshot.docs.length - 1]);
                } else {
                    commit('setAllMaleSearchResultsLoaded', true);
                }
            } catch (error) {
                commit('setErrorMessage', "さらに男性の検索結果を読み込む際にエラーが発生しました。");
            }
        },
        async loadMoreFemaleSearchResults({ commit, state }) {
            const { searchConditions } = state;
            const db = getFirestore();
            let q = query(collection(db, 'review'), where('gender', '==', '女性'));
            if (searchConditions.twitterId.trim()) {
                q = query(q, where('twitter_id', '==', searchConditions.twitterId.trim()));
            }
            if (searchConditions.content.trim()) {
                q = query(q, where('content', '==', searchConditions.content.trim()));
            }
            if (searchConditions.place.trim()) {
                q = query(q, where('place', '==', searchConditions.place.trim()));
            }
            if (searchConditions.minPrice) {
                q = query(q, where('price', '>=', searchConditions.minPrice));
            }
            if (searchConditions.maxPrice) {
                q = query(q, where('price', '<=', searchConditions.maxPrice));
            }
            if (searchConditions.minPrice || searchConditions.maxPrice) {
                q = query(q, orderBy('price'), orderBy('postedAt', 'desc'));
            } else {
                q = query(q, orderBy('postedAt', 'desc'));
            }
            if (state.lastVisibleFemaleSearchResult) {
                q = query(q, startAfter(state.lastVisibleFemaleSearchResult), limit(5));
            } else {
                q = query(q, limit(5));
            }
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const newResults = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    commit('appendFemaleSearchResults', newResults);
                    commit('setLastVisibleFemaleSearchResult', querySnapshot.docs[querySnapshot.docs.length - 1]);
                } else {
                    commit('setAllFemaleSearchResultsLoaded', true);
                }
            } catch (error) {
                commit('setErrorMessage', "さらに女性の検索結果を読み込む際にエラーが発生しました。");
            }
        },
        showErrorMessage({ commit }, message) {
            commit('setErrorMessage', message);
            setTimeout(() => {
                commit('clearErrorMessage');
            }, 5000);
        },
        clearSearchResults({ commit }) {
            commit('clearSearchConditions');
            commit('clearSearchResults');
        },
        clearGender({ commit }) {
            commit('clearGender');
        },
        resetInfiniteScroll({ commit }) {
            commit('resetInfiniteScroll');
        },
        async updateAllPostsByTwitterId({ commit }, { twitter_id, newTwitterId, userId }) {
            const db = getFirestore();
            const { writeBatch, Timestamp } = await import('firebase/firestore');
            const batch = writeBatch(db);
            try {
                const postsQuery = query(collection(db, 'review'), where('twitter_id', '==', twitter_id));
                const querySnapshot = await getDocs(postsQuery);
                querySnapshot.forEach((document) => {
                    const postRef = doc(db, 'review', document.id);
                    const repliesCollection = collection(postRef, 'replies');
                    const newReplyRef = doc(repliesCollection);
                    const now = Timestamp.now();
                    const date = now.toDate();
                    const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
                    batch.set(newReplyRef, {
                        newTwitterId: newTwitterId,
                        updatedAt: formattedDate,
                        userId: userId,
                    });
                });
                await batch.commit();
            } catch (error) {
                commit('setErrorMessage', "更新に失敗しました。" + error.message);
            }
        },
        async fetchReplies({ commit }, { postId }) {
            try {
                const repliesQuery = query(collection(db, 'review', postId, 'replies'), orderBy('updatedAt', 'desc'));
                const querySnapshot = await getDocs(repliesQuery);
                const replies = [];
                querySnapshot.forEach(doc => {
                    replies.push({ id: doc.id, ...doc.data() });
                });
                commit('setReplies', { postId, replies });
            } catch (error) {
                return;
            }
        },
    },
    getters: {
        userPosts: (state) => (userId) => {
            return Object.values(state.userPosts)
                .filter(post => post.userId === userId)
                .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt));
        },
        malePosts: state => state.malePosts,
        femalePosts: state => state.femalePosts,
    },
});