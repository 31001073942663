// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import store from './store';

const firebaseConfig = {
  apiKey: "AIzaSyCns-kT8tYagfaEH2-QynunBY_SQ2hXBRs",
  authDomain: "papalog-f4346.firebaseapp.com",
  projectId: "papalog-f4346",
  storageBucket: "papalog-f4346.appspot.com",
  messagingSenderId: "1031701050065",
  appId: "1:1031701050065:web:d822b5e816e72e071c8bf0",
  measurementId: "G-L8YEWEGTGL"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formDataCollection = collection(db, "review");
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit('setUserUID', user.uid);
  }
  store.commit('setLoggedIn', !!user);
});

const getUserInfo = () => {
  return auth.currentUser;
};

export { db, formDataCollection, getDocs, collection, auth, addDoc, getUserInfo, storage };