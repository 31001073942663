import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'パパログ | パパ活の口コミ（レポ）を投稿しておすすめの子を見つけよう',
      description: 'パパログは、パパ活をする女性（パパ活女子、PJ）と男性（パパ）のための信頼性の高い口コミ・レポを投稿するサイトです。安心してパパ活を始めるための情報を共有し、おすすめのパパや人気のパパ活女子を見つけましょう。'
    },
  },
  {
    path: '/post',
    name: 'post',
    component: () => import('../views/PostView.vue'),
    meta: {
      requiresAuth: true,
      title: '投稿 | パパログ',
      description: 'パパログの投稿ページです。パパ活に関する口コミを投稿できます。'
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignupView.vue'),
    meta: {
      title: '新規アカウント作成 | パパログ',
      description: 'パパログの新規アカウント作成ページです。メールアドレスとパスワードの設定により新しくアカントを作成できます。'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: 'ログイン | パパログ',
      description: 'パパログのログインページです。ログインを行い口コミを投稿しましょう。'
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserView.vue'),
    meta: {
      requiresAuth: true,
      title: 'マイページ | パパログ',
      description: 'パパログのマイページです。自分で投稿した口コミを管理することができます。'
    },
  },
  {
    path: '/using',
    name: 'using',
    component: () => import('../views/UsingView.vue'),
    meta: {
      title: '使い方 | パパログ',
      description: 'パパログの使い方ページです。パパログの使い方を詳しく説明し、よくある質問なども記載しています。'
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue'),
    meta: {
      title: '利用規約 | パパログ',
      description: 'パパログの利用規約ページです。。'
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue'),
    meta: {
      title: 'プライバシーポリシー | パパログ',
      description: 'パパログのプライバシーポリシーページです。本サイトが収集している情報およびその用途、そしてユーザーに認められている情報のコントロール権について確認するにはこのページをご覧ください。'
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// ナビゲーションガードの設定
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  try {
    await new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        const isAuthenticated = !!user;
        if (requiresAuth && !isAuthenticated) {
          unsubscribe(); // 監視を解除
          next('/login'); // ログインが必要なページにアクセスしようとした場合、ログインページにリダイレクト
          resolve(); // Promiseを解決
        } else {
          unsubscribe(); // 監視を解除
          next();
          resolve(); // Promiseを解決
        }
      }, error => {
        unsubscribe(); // 監視を解除
        next('/login'); // エラーが発生した場合もログインページにリダイレクト
        reject(error); // Promiseを拒否
      });
    });
  } catch (error) {
    this.$store.dispatch("showErrorMessage", "エラー");
  }
});

router.afterEach((to, from) => {
  // toとfromが同じパスを指している場合、ページの最上部にスクロール
  if (to.path === from.path) {
    window.scrollTo(0, 0);
  }

  // ページ遷移後にタイトルを設定
  const defaultTitle = 'パパログ | パパ活の口コミ（レポ）を投稿しておすすめの子を見つけよう';
  document.title = to.meta.title || defaultTitle;

  // メタディスクリプションの更新
  const metaDescription = document.querySelector('meta[name="description"]');
  const defaultDescription = 'パパログは、パパ活をする女性（パパ活女子、PJ）と男性（パパ）のための信頼性の高い口コミ・レポを投稿するサイトです。安心してパパ活を始めるための情報を共有し、おすすめのパパや人気のパパ活女子を見つけましょう。';
  metaDescription.setAttribute('content', to.meta.description || defaultDescription);
});

export default router
